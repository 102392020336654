import React from "react";

const Footer = () => (
  <div className="footer">
    <p>Appfluencer® is a Registered Trademark of Shared2you, Inc. Copyright © 2012-
        {new Date().getFullYear()}
        {". All Rights Reserved."}</p>
  </div>
);

export default Footer;