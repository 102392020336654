import React, { Component, Fragment } from 'react';
import background from '/Users/brettbauer1/appfluencer-targeting/src/Assets/img/relaxing.jpg';
import Button from '/Users/brettbauer1/appfluencer-targeting/src/components/Button.js';
import '/Users/brettbauer1/appfluencer-targeting/src/styles.css';
import '/Users/brettbauer1/appfluencer-targeting/src/App.css';
import '/Users/brettbauer1/appfluencer-targeting/src/index.css';
//import TopAppBar from './components/TopAppBar/TopAppBar.js';
import Footer from '/Users/brettbauer1/appfluencer-targeting/src/components/footer.js';



class Home extends Component {
  render() {
    return (
      <Fragment>
        <div className="hero-image darken-overlay">
          <div className="mdl-layout mdl-js-layout mdl-layout--fixed-header"
          style={{ backgroundImage: `url(${background})`,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            minHeight: ['1400px', '100%'],
            minWidth: ['100%', '400px']
           }}>
          <div>
            <h1
              style={{ color: "ghostwhite",
              justify: "center",
              marginTop: 0,
              marginBottom: 0,
              paddingTop: "100px",
              paddingBottom: "100px",
              fontFamily: "Lato, sans-serif",
              fontSize: "60px",
              fontWeight: "500",
              }}>
               Appfluencer<sup>®</sup></h1>
            </div>
          <div>
            <h4
              style={{color: "ghostwhite",
              justify: "center",
              paddingBottom: "100px",
              fontFamily: "Lato, sans-serif",
              fontSize: "60px",
              fontWeight: "500",
              textShadow: "2px 2px 1px black"
              }}>
              Privacy Compliant Targeting
              </h4>
          </div>
              <Button />
          <div>
            <h4
              style={{ color: "ghostwhite",
              justify: "center",
              paddingTop: "50px",
              paddingBottom: "500px",
              fontFamily: "Lato, sans-serif",
              fontSize: "30px",
              fontWeight: "500",
              }}>
              Eliminate the need for personal data in mobile advertising
            </h4>
          </div>
          </div>
        </div>
        <footer>
          <Footer />
        </footer>
      </Fragment>
    );
  };
}

export default Home;