
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Stack from '@mui/material/Stack';
import { useButton } from '@mui/base/ButtonUnstyled';
import { styled } from '@mui/system';
import "/Users/brettbauer1/appfluencer-targeting/src/styles.css";


const ExecuteButtonRoot = styled('button')`
  background-color: #0059b2;
  padding: 20px 30px;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  font-family: Lato, sans-serif;
  font-size: 28px;
  transition: all 200ms ease;
  cursor: pointer;
  border: "2px";
  textShadow: "2px 2px 1px black";

  &:hover {
    background-color: #11254F;
  }

  &.active {
    background-color: #004386;
  }

`;

const ExecuteButton = React.forwardRef(function ExecuteButton(props, ref) {
  const { children } = props;
  const { active, focusVisible, getRootProps } = useButton({
    ...props,
    ref,
    component: ExecuteButtonRoot,
  });

  const classes = {
    active,
    focusVisible,
  };

  return (
    <ExecuteButtonRoot {...getRootProps()} className={clsx(classes)}>
      {children}
    </ExecuteButtonRoot>
  );
});

ExecuteButton.propTypes = {
  children: PropTypes.node,
};

export default function UseButton() {
   return (
     <Stack
       spacing={2}
       direction="row"
       display="flex"
       justifyContent="center"
       paddingTop="15px"
       paddingBottom="20px"
       >
       <ExecuteButton
       type="button"
       onClick={(e) => {
         e.preventDefault();
         window.location.href="https://login.appfluencer.com";}}>
         GET STARTED
         </ExecuteButton>
     </Stack>
   );
 }
